import Vue from "vue";
import Router from "vue-router";
 
// import Presentation from "./paginas/Presentation.vue";
// import MainNavbar from "./layout/MainNavbar.vue";
// import MainFooter from "./layout/MainFooter.vue";
// import Inicio from "./paginas/inicio.vue";
// import Components from "./paginas/Contact.vue";
// import representacionesiframe from "./paginas/representacioniframe.vue";
// import tarjeta from "./paginas/tarjeta.vue";
// import chapultepeciframe from "./paginas/chapultepeciframe.vue";

// const Presentation = () =>  import ("./paginas/Presentation.vue");
const MainNavbar = () =>  import ("./layout/MainNavbar.vue");
const MainFooter = () =>  import ("./layout/MainFooter.vue");
const Inicio = () =>  import ("./paginas/inicio.vue");
const Components = () =>  import ("./paginas/Contact.vue");
const representacionesiframe = () =>  import ("./paginas/representacioniframe.vue");
const tarjeta = () =>  import ("./paginas/tarjeta.vue");
const chapultepeciframe = () =>  import ("./paginas/chapultepeciframe.vue");

Vue.use(Router);

const router = new Router({
  routes: [
    // {
    //   path: "/",
    //   redirect: "/inicio"
    // },
    {
      path: "/:representacion?",
      name: "inicio",
      components: {default: Inicio, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 500}}
    },
    {
      path: "/quienessomos",
      name: "quienessomos",
      components: {default: Inicio, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 500}}
    },
    {
      path: "/pricing",
      name: "pricing",
      components: {default: Inicio, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 500}}
    },
    {
      path: "/requisitos",
      name: "requisitos",
      components: {default: Inicio, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 500}}
    },
    {
      path: "/solicitudes",
      name: "solicitudes",
      components: {default: Inicio, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 500}}
    },
    {
      path: "/findus",
      name: "findus",
      components: {default: Inicio, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 500}}
    },
    {
      path: "/eventos",
      name: "eventos",
      components: {default: Inicio, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 500}}
    },
    {
      path: "/Components",
      name: "Components",
      components: {default: Components, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 500}}
    },
    {
      path: "/SanAngel/inicio",
      name: "SanAngel",
      components: {default: representacionesiframe},
      props: {header: {colorOnScroll: 500}}
    },
    // {
    //   path: "/Chapultepec/Inicio",
    //   name: "Chapultepec",
    //   components: {default: chapultepeciframe},
    //   props: {header: {colorOnScroll: 500}}
    // },
    {
      path: "/tarjeta/:nombre?",
      name: "tarjeta",
      components: {default: tarjeta},
      props: {header: {colorOnScroll: 500}}
    }
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
});

export default router;
